import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import RequiredStar from '@/assets/required_star.svg';
import Typography from '@components/atoms/Typography';
import { ElementType, ShortAnswerType } from '@components/types/\bform';
import { useState } from 'react';
import BlackWhiteInput from '../Input';
import { updateOrAddData } from '../utils';

interface ShortAnswerElementProps extends Omit<ElementType, 'data'> {
  data: ShortAnswerType;
}

const ShortAnswerElement = ({
  data,
  colorList,
  responseData,
  setResponseData,
}: ShortAnswerElementProps) => {
  const [inputData, setInputData] = useState<string>('');

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputData(e.target.value);

    updateOrAddData(responseData, setResponseData, {
      id: data.id.toString(),
      value: e.target.value,
    });
  };

  return (
    <Spacing col gap={25}>
      <Spacing justifyContent="space-between" alignItems="center">
        <Spacing gap={3} alignItems="center">
          {data.required === 1 && <img src={RequiredStar} width={12} height={12} />}
          <Typography color={getRgbaText(colorList.font)} size={16} weight={700} height={26}>
            {data.question}
          </Typography>
        </Spacing>
      </Spacing>
      <BlackWhiteInput
        onChange={handleChangeInput}
        value={
          responseData.filter((item) => item.id === data.id.toString())[0]
            ? responseData.filter((item) => item.id === data.id.toString())[0].value
            : inputData
        }
      />
    </Spacing>
  );
};

export default ShortAnswerElement;
