import styled from '@emotion/styled';
import React from 'react';
import InputProps from '../types/atoms/input';

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ iconLeft, iconRight, onChange, ...rest }, ref) => (
    <Container>
      {iconLeft && iconLeft}
      <StyledInput onChange={onChange ? onChange : () => {}} ref={ref} {...rest} />
      {iconRight && iconRight}
    </Container>
  )
);

Input.displayName = 'Input';

const Container = styled.div``;

const StyledInput = styled.input<InputProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: ${(props) => (props.border ? props.border : '1px solid #cdd3db')};
  outline: none;
  box-sizing: border-box;

  text-align: ${(props) => props.textAlign};

  padding: 10px;

  color: #646464;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;

  &::placeholder {
    color: #cdd3db;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.2px;
  }
`;

export default Input;
