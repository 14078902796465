import { ColorType } from '@components/form/theme';

const calculateHoverColor = (color: string, isHover: boolean) => {
  // 변환할 비율
  const brightnessIncrease = 0.3;
  const rgbaValues = color.match(/\d+/g).map(Number);

  const hoverColor = `rgba(${rgbaValues[0]}, ${rgbaValues[1]}, ${rgbaValues[2]}, ${
    isHover ? rgbaValues[3] - rgbaValues[3] * brightnessIncrease : 1
  })`;

  return hoverColor;
};

const getRgbaText = (value: ColorType) => {
  return `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`;
};

export { calculateHoverColor, getRgbaText };
