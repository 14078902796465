import { getFormAction } from '@/api';
import Logo from '@/assets/logo.svg';
import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import Typography from '@components/atoms/Typography';
import TopArticle from '@components/common/TopArticle';
import { Custom, ThemeColorListType } from '@components/form/theme';
import { FormDataRequestType } from '@components/types/\bform';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const Submit = () => {
  const params = useParams();
  const [colorList, setColorList] = useState<ThemeColorListType>(Custom);

  const handleGetForm = async () => {
    try {
      const response: FormDataRequestType = await getFormAction(params.id);

      setColorList(response.data.theme.theme);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    handleGetForm();
  }, []);

  return (
    <Container backgroundColor={getRgbaText(colorList.outside)}>
      <Spacing col gap={40} alignItems="center">
        <Spacing col alignItems="center" gap={60}>
          <TopArticle
            title="제출에 성공하였습니다!"
            fontColor={getRgbaText(colorList.font)}
            isSubTitle
          />
          <Typography color={getRgbaText(colorList.font)} size={30} weight={600} height={42}>
            감사합니다!
          </Typography>
        </Spacing>
        <BottomBox onClick={() => (window.location.href = 'https://form.phaze2.io/')}>
          <Typography color={getRgbaText(colorList.font)} size={30} weight={600} height={42}>
            Formark
          </Typography>
          <LogoBox>
            <img src={Logo} width={40} height={40} />
          </LogoBox>
        </BottomBox>
      </Spacing>
    </Container>
  );
};

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};

  @media screen and (min-height: 100px) {
    height: 100vh;
  }

  box-sizing: border-box;
  padding: 30px;
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: white;
`;

const BottomBox = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
`;

export default Submit;
