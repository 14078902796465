import { color } from '@/styles/color';
import { getRgbaText } from '@/utils';
import Button from '@components/atoms/Button';
import Spacing from '@components/atoms/Spacing';
import { FormDataResponseType, FormType } from '@components/types/\bform';
import { SetStateAction, useState } from 'react';

import { Bounce, toast } from 'react-toastify';
import { getFormCardElement } from './Action';
import { ThemeColorListType } from './theme';
import { checkRequiredData } from './utils';

interface FormCardListProps {
  dataList: FormType[];
  colorList: ThemeColorListType;
  responseData: FormDataResponseType[];
  setResponseData: React.Dispatch<SetStateAction<FormDataResponseType[]>>;
}

const FormCardList = ({
  dataList,
  colorList,
  responseData,
  setResponseData,
}: FormCardListProps) => {
  const [currentCard, setCurrentCard] = useState<number>(dataList[0].id);

  const handleChangeCurrentCard = (id: number, required: number, data: FormType) => {
    const response = checkRequiredData(
      responseData,
      required === 1 ? true : false,
      currentCard.toString(),
      data
    );

    if (!response) {
      toast.error('필수 입력 값입니다', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      return;
    }
    setCurrentCard(id);
  };

  return (
    <div>
      {dataList.map((data, index) => {
        if (data.id === currentCard)
          return (
            <Spacing key={data.id} col gap={20}>
              {getFormCardElement(data.type, data, colorList, responseData, setResponseData)}
              <Spacing
                justifyContent={currentCard !== dataList[0].id ? 'space-between' : 'end'}
                width="100%"
                alignItems="center"
              >
                {currentCard !== dataList[0].id && (
                  <Button
                    onClick={() => handleChangeCurrentCard(dataList[index - 1].id, 0, data)}
                    padding="8px 16px"
                    borderRadius="8px"
                    border={`1px solid ${color.grey06}`}
                    backgroundColor={getRgbaText(colorList.button)}
                    font={{
                      size: 18,
                      color: getRgbaText(colorList.buttonFont),
                      weight: 600,
                      height: 18,
                    }}
                  >
                    이전
                  </Button>
                )}

                {index !== dataList.length - 1 && (
                  <Button
                    onClick={() =>
                      handleChangeCurrentCard(
                        dataList[index + 1].id,
                        dataList.filter((ele) => ele.id === currentCard)[0].required,
                        data
                      )
                    }
                    padding="8px 16px"
                    borderRadius="8px"
                    backgroundColor={getRgbaText(colorList.button)}
                    font={{
                      size: 18,
                      color: getRgbaText(colorList.buttonFont),
                      weight: 600,
                      height: 18,
                    }}
                  >
                    다음
                  </Button>
                )}
              </Spacing>
            </Spacing>
          );
      })}
    </div>
  );
};

export default FormCardList;
