const color = {
  primary: '#f9bb5f',
  secondary: '#f9a55f',
  tertiary: '#f97d5f',
  black: '#000',
  white: '#fff',
  grey01: '#f7f7f7',
  grey02: '#f3f4f4',
  grey03: '#eeeeee',
  grey04: '#eaebeb',
  grey05: '#e5e6e6',
  grey06: '#d0d1d1',
  grey07: '#a3a3a3',
  grey08: '#7e7f7f',
  grey09: '#606161',
  black01: '#b6b6b6',
  black02: '#929292',
  black03: '#606060',
  black04: '#414141',
  black05: '#121212',
  black06: '#101010',
  black07: '#0d0d0d',
  black08: '#0a0a0a',
  black09: '#080808',
  background: '#ffdf9040',
  purple: '#dcb0fd',
  green: '#7df35f',
  red: '#fc6c6c',
  blue: '#66c2f8',
  yellow: '#faf53c',
  brown: '#c5a35c',
};

export { color };
