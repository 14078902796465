import { color } from '@/styles/color';
import styled from '@emotion/styled';

interface BodyProps {
  children: React.ReactElement;
}

const Body = ({ children }: BodyProps) => {
  return <Box>{children}</Box>;
};

const Box = styled.main`
  display: flex;
  justify-content: center;
  padding: 60px;

  background-color: ${color.background};
`;

export default Body;
