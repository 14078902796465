import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import Typography from '@components/atoms/Typography';
import { FormDataResponseType, FormType } from '@components/types/\bform';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SetStateAction } from 'react';
import FormCardList from './FormCardList';
import { ThemeColorListType } from './theme';

interface FormCardProps {
  colorList: ThemeColorListType;
  os: string;
  isPreview?: boolean;
  data: FormType;
  responseData: FormDataResponseType[];
  setResponseData: React.Dispatch<SetStateAction<FormDataResponseType[]>>;
}

const FormCard = ({
  colorList,
  os,
  isPreview,
  data,
  responseData,
  setResponseData,
}: FormCardProps) => {
  return !Array.isArray(data) && data.type === 'section' ? (
    <InnerBox>
      <SectionTitle
        isPreview={isPreview}
        borderColor={getRgbaText(colorList.border)}
        backgroundColor={getRgbaText(colorList.inside)}
        os={os}
      >
        <Spacing col gap={12}>
          <Typography
            color={getRgbaText(colorList.font)}
            size={21}
            weight={700}
            height={26}
            whiteSpace="pre-line"
          >
            {data.title}
          </Typography>
          <Typography
            color={getRgbaText(colorList.font)}
            size={18}
            weight={500}
            height={16}
            whiteSpace="pre-line"
          >
            {data.desc}
          </Typography>
        </Spacing>
      </SectionTitle>
      <ContentBox
        isPreview={isPreview}
        borderColor={getRgbaText(colorList.border)}
        backgroundColor={getRgbaText(colorList.inside)}
        os={os}
        type="section"
      >
        <FormCardList
          dataList={data.children}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      </ContentBox>
    </InnerBox>
  ) : (
    <InnerBox>
      <ContentBox
        isPreview={isPreview}
        borderColor={getRgbaText(colorList.border)}
        backgroundColor={getRgbaText(colorList.inside)}
        os={os}
        type=""
      >
        <FormCardList
          dataList={data as FormType[]}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      </ContentBox>
    </InnerBox>
  );
};

const InnerBox = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 15px 0;
  align-items: center;
  flex-direction: column;
`;

const SectionTitle = styled.div<{
  borderColor: string;
  backgroundColor: string;
  os: string;
  isPreview: boolean;
}>`
  box-sizing: border-box;
  display: flex;

  @media screen and (min-width: 692px) {
    ${(props) =>
      props.isPreview && props.os === 'PC'
        ? css`
            width: 550px;
          `
        : css`
            width: 380px;
          `}
  }

  padding: 15px 15px;
  flex-direction: column;
  border-radius: 8px 8px 0 0;

  border: 1px solid ${(props) => props.borderColor};
  border-bottom: none;
  background-color: ${(props) => props.backgroundColor};
`;

const ContentBox = styled.div<{
  borderColor: string;
  backgroundColor: string;
  os: string;
  type: string;
  isPreview: boolean;
}>`
  box-sizing: border-box;
  display: flex;

  width: 100%;
  height: 100%;

  @media screen and (min-width: 692px) {
    ${(props) =>
      props.os === 'PC'
        ? css`
            width: 550px;
          `
        : css`
            width: 380px;
          `}
  }

  @media screen and (max-width: 750px) {
    width: 550px;
  }

  @media screen and (max-width: 650px) {
    width: 500px;
  }

  @media screen and (max-width: 550px) {
    width: 450px;
  }
  @media screen and (max-width: 450px) {
    width: 350px;
  }

  @media screen and (max-width: 391px) {
    width: 300px;
  }

  @media screen and (max-width: 350px) {
    width: 250px;
  }

  padding: 30px 15px;
  flex-direction: column;

  ${(props) =>
    props.type === 'section'
      ? css`
          border-radius: 0 0 8px 8px;
        `
      : css`
          border-radius: 8px;
        `}

  border: 1px solid ${(props) => props.borderColor};

  background-color: ${(props) => props.backgroundColor};
`;

export default FormCard;
