import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import RequiredStar from '@/assets/required_star.svg';
import Typography from '@components/atoms/Typography';
import Radio from '@components/atoms/radio/Radio';
import { ElementType, RadioType } from '@components/types/\bform';
import { useState } from 'react';
import BlackWhiteInput from '../Input';
import { updateOrAddData } from '../utils';

interface RadioElementProps extends Omit<ElementType, 'data'> {
  data: RadioType;
}

interface RadioValueListProps {
  value: string;
  checked: boolean;
  id: string;
}

const RadioElement = ({ data, colorList, responseData, setResponseData }: RadioElementProps) => {
  const [radioValueList, setRadioValueList] = useState<RadioValueListProps[]>(
    data.choices.map((ele) => {
      return { value: ele, checked: false, id: data.id.toString() };
    })
  );

  const [inputValue, setInputValue] = useState<string>('');

  const handleChangeInputValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);

    updateOrAddData(responseData, setResponseData, {
      id: `${data.id.toString()}.other_option_response`,
      value: e.target.value,
    });

    updateOrAddData(responseData, setResponseData, {
      id: data.id.toString(),
      value: '__other_option__',
    });
  };

  const handleCheckedRadio = (index: number) => {
    const updatedRadioValueList = radioValueList.map((radio, i) => {
      if (i === index) {
        return { ...radio, checked: !radio.checked };
      } else {
        return { ...radio, checked: false };
      }
    });

    setResponseData(
      responseData.filter((item) => item.id !== `${data.id.toString()}.other_option_response`)
    );

    const target = updatedRadioValueList.filter((value) => value.checked === true)[0];

    updateOrAddData(responseData, setResponseData, {
      id: data.id.toString(),
      value: target.value === '기타' ? '__other_option__' : target.value,
    });

    setRadioValueList(updatedRadioValueList);
  };

  const getRadioChecked = (index: number, id: number, value: string) => {
    if (responseData.length === 0) {
      return radioValueList[index].checked;
    } else {
      if (responseData.filter((target) => target.id === id.toString())[0]?.value === value) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <Spacing col gap={25}>
      <Spacing justifyContent="space-between" alignItems="center">
        <Spacing gap={3} alignItems="center">
          {data.required === 1 && <img src={RequiredStar} width={12} height={12} />}
          <Typography color={getRgbaText(colorList.font)} size={16} weight={700} height={26}>
            {data.question}
          </Typography>
        </Spacing>
      </Spacing>
      <Spacing col gap={10}>
        {data.choices.map((element, index) => {
          return (
            <Spacing col gap={8} key={data.id + element}>
              <Radio
                label={element}
                checked={getRadioChecked(
                  index,
                  data.id,
                  element === '기타' ? '__other_option__' : element
                )}
                color={getRgbaText(colorList.element)}
                id={data.id + 'radio' + element}
                onChange={() => handleCheckedRadio(index)}
                value={data.id + 'radio' + element}
              />
              {responseData.filter(
                (item) =>
                  item.id.includes(data.id.toString()) &&
                  (item.value === '기타' || item.value === '__other_option__')
              ).length > 0 &&
                radioValueList[index].value === '기타' && (
                  <BlackWhiteInput value={inputValue} onChange={handleChangeInputValue} />
                )}
            </Spacing>
          );
        })}
      </Spacing>
    </Spacing>
  );
};

export default RadioElement;
