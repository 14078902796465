import styled from '@emotion/styled';
import { Outlet } from 'react-router-dom';
import Body from './Body';
import Header from './Header';

const Layout = () => {
  return (
    <Container>
      <Header />
      <Body>
        <Outlet />
      </Body>
      {/* <Footer /> */}
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  @media (min-width: 1440px) {
    justify-content: center;
  }

  flex-direction: column;
`;

export default Layout;
