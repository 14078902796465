import { color } from '@/styles/color';
import { getRgbaText } from '@/utils';
import Button from '@components/atoms/Button';
import Spacing from '@components/atoms/Spacing';
import RequiredStar from '@/assets/required_star.svg';
import Typography from '@components/atoms/Typography';
import Radio from '@components/atoms/radio/Radio';
import { ElementType, LinearType } from '@components/types/\bform';
import styled from '@emotion/styled';
import { useState } from 'react';
import { updateOrAddData } from '../utils';

interface LinearElementProps extends Omit<ElementType, 'data'> {
  data: LinearType;
}

interface LinearValueListProps {
  value: string;
  checked: boolean;
  id: number;
}

const LinearElement = ({ data, colorList, responseData, setResponseData }: LinearElementProps) => {
  const [linearValueList, setLinearValueList] = useState<LinearValueListProps[]>(
    data.choices.map((ele) => {
      return { value: ele, checked: false, id: data.id };
    })
  );

  const handleCheckedLinear = (index: number) => {
    const updatedLinearValueList = linearValueList.map((linear, i) => {
      if (i === index) {
        return { ...linear, checked: !linear.checked };
      } else {
        return { ...linear, checked: false };
      }
    });

    const target = updatedLinearValueList.filter((value) => value.checked === true)[0];
    updateOrAddData(responseData, setResponseData, {
      id: data.id.toString(),
      value: target.value,
    });

    setLinearValueList(updatedLinearValueList);
  };

  const handleCleanLinear = () => {
    const updatedLinearValueList = linearValueList.map((linear) => {
      return { ...linear, checked: false };
    });

    setResponseData((prevData) =>
      prevData.filter((item) => item.id !== data.id.toString() || item.value !== item.value)
    );

    setLinearValueList(updatedLinearValueList);
  };

  const getLinearChecked = (index: number, id: number, value: string) => {
    if (responseData.length === 0) {
      return linearValueList[index].checked;
    } else {
      if (responseData.filter((target) => target.id === id.toString())[0]?.value === value) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <Spacing col gap={25}>
      <Spacing justifyContent="space-between" alignItems="center">
        <Spacing gap={3} alignItems="center">
          {data.required === 1 && <img src={RequiredStar} width={12} height={12} />}
          <Typography color={getRgbaText(colorList.font)} size={16} weight={700} height={26}>
            {data.question}
          </Typography>
        </Spacing>
      </Spacing>
      {data.desc && (
        <Typography color={getRgbaText(colorList.font)} size={18} weight={500} height={16}>
          {data.desc}
        </Typography>
      )}

      <Spacing col gap={12}>
        <SlideSpacing>
          {data.min && (
            <Spacing alignItems="center">
              <Typography
                whiteSpace="nowrap"
                color={getRgbaText(colorList.font)}
                size={16}
                weight={400}
                height={18}
              >
                {data.min}
              </Typography>
            </Spacing>
          )}
          {data.choices.map((element, index) => {
            return (
              <Spacing gap={8} width="100%" key={data.id + element}>
                <Radio
                  width="100%"
                  label={element}
                  checked={getLinearChecked(index, data.id, element)}
                  color={getRgbaText(colorList.element)}
                  id={data.id + 'linear' + element}
                  onChange={() => handleCheckedLinear(index)}
                  value={data.id + 'linear' + element}
                />
              </Spacing>
            );
          })}
          {data.max && (
            <Spacing alignItems="center">
              <Typography
                whiteSpace="nowrap"
                color={getRgbaText(colorList.font)}
                size={16}
                weight={400}
                height={18}
              >
                {data.max}
              </Typography>
            </Spacing>
          )}
        </SlideSpacing>
        {linearValueList.filter((value) => value.checked === true).length > 0 && (
          <Spacing width="100%" justifyContent="end">
            <Button
              onClick={handleCleanLinear}
              padding="8px 16px"
              borderRadius="8px"
              backgroundColor={color.white}
              border={`1px solid ${color.grey07}`}
              font={{
                size: 16,
                color: getRgbaText(colorList.buttonFont),
                weight: 700,
                height: 18,
              }}
            >
              Clean
            </Button>
          </Spacing>
        )}
      </Spacing>
    </Spacing>
  );
};

const SlideSpacing = styled.div`
  display: flex;
  gap: 6px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 5px;
`;

export default LinearElement;
