import styled from '@emotion/styled';
import React from 'react';
import { ButtonProps } from '../types/atoms/button';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      border = '0',
      backgroundColor,
      width,
      height,
      padding,
      borderRadius = '0',
      font,
      ...props
    },
    ref?: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <StyledButton
        border={border}
        backgroundColor={backgroundColor}
        width={width}
        height={height}
        padding={padding}
        borderRadius={borderRadius}
        font={font}
        onClick={props.onClick}
        ref={ref}
      >
        {children}
      </StyledButton>
    );
  }
);

const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: ${(props) => props.border};
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: ${(props) => props.padding};
  border-radius: ${(props) => props.borderRadius};

  box-sizing: border-box;

  font-size: ${(props) => props.font?.size}px;
  color: ${(props) => props.font?.color};
  font-weight: ${(props) => props.font?.weight};
  line-height: ${(props) => props.font?.height}px;
  letter-spacing: ${(props) => props.font?.letterSpacing};

  white-space: nowrap;

  cursor: pointer;
`;

Button.displayName = 'Button';

export default Button;
