import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import RequiredStar from '@/assets/required_star.svg';
import Typography from '@components/atoms/Typography';
import { ElementType, TimeType } from '@components/types/\bform';
import BlackWhiteInput from '../Input';
import Dropdown from '@components/atoms/Drodown';
import { useState } from 'react';
import { color } from '@/styles/color';
import { updateOrAddData } from '../utils';

interface TimeElementProps extends Omit<ElementType, 'data'> {
  data: TimeType;
}

type TimeValueType = {
  hour: string;
  minute: string;
  type: string;
};

const TimeElement = ({ data, colorList, responseData, setResponseData }: TimeElementProps) => {
  const [timeValue, setTimeValue] = useState<TimeValueType>({
    hour: '12',
    minute: '00',
    type: 'AM',
  });

  const handleChangeType = (value: string) => {
    setTimeValue({ ...timeValue, type: value });
  };

  const handleChangeHour = (value: string) => {
    setTimeValue({ ...timeValue, hour: value });

    updateOrAddData(responseData, setResponseData, {
      id: `${data.id.toString()}_hour`,
      value: timeValue.type === 'AM' ? timeValue.hour : (Number(timeValue.hour) + 12).toString(),
    });
  };

  const handleChangeMinute = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regexPattern: RegExp = /^(?:[0-9]|[1-5][0-9]|59|\s*)$/;
    // 사용 예시
    const userInput: string = e.target.value;

    if (regexPattern.test(userInput)) {
      setTimeValue({ ...timeValue, minute: userInput });
    }
    updateOrAddData(responseData, setResponseData, {
      id: `${data.id.toString()}_minute`,
      value: timeValue.minute,
    });
  };

  return (
    <Spacing col gap={25}>
      <Spacing justifyContent="space-between" alignItems="center">
        <Spacing gap={3} alignItems="center">
          {data.required === 1 && <img src={RequiredStar} width={12} height={12} />}
          <Typography color={getRgbaText(colorList.font)} size={16} weight={700} height={26}>
            {data.title}
          </Typography>
        </Spacing>
      </Spacing>
      <Spacing gap={5} alignItems="center">
        <Dropdown
          height={36}
          optionList={['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']}
          value={timeValue.hour}
          onChange={handleChangeHour}
          style={{ border: `1px solid ${color.grey06}`, borderRadius: '6px' }}
        />
        <Typography color={getRgbaText(colorList.font)} size={18} weight={400} height={18}>
          :
        </Typography>
        <BlackWhiteInput value={timeValue.minute} type="text" onChange={handleChangeMinute} />
        <Dropdown
          height={36}
          placeholder="PM"
          optionList={['AM', 'PM']}
          value={timeValue.type}
          onChange={handleChangeType}
          style={{ border: `1px solid ${color.grey06}`, borderRadius: '6px' }}
        />
      </Spacing>
    </Spacing>
  );
};

export default TimeElement;
