import { ThemeColorListType } from '@components/form/theme';
import axiosWrapper from './fetch';
import { ChangeFormResponseType } from './type';
import { FormDataRequestType, FormDataResponseType } from '@components/types/\bform';

const getFormAction = async (url: string) => {
  try {
    const response: FormDataRequestType = await axiosWrapper.get(`form/${url}`);
    return response;
  } catch (error) {
    console.error('Error in getFormAction:', error);
    throw error;
  }
};

const changeFormAction = async (formUrl: string) => {
  try {
    const response: ChangeFormResponseType = await axiosWrapper.post('form', { url: formUrl });
    return response;
  } catch (error) {
    console.error('Error in changeFormAction:', error);
    throw error;
  }
};

const createFormAction = async (formUrl: string, theme: ThemeColorListType) => {
  try {
    const response = await axiosWrapper.put(`form/${formUrl}/theme`, { theme });
    return response;
  } catch (error) {
    console.error('Error in createFormAction:', error);
    throw error;
  }
};

const submitFormAction = async (formUrl: string, data: FormDataResponseType[]) => {
  try {
    const response: ChangeFormResponseType = await axiosWrapper.post(`form/${formUrl}`, {
      data: data,
    });
    return response;
  } catch (error) {
    console.error('Error in submitFormAction:', error);
    throw error;
  }
};

export { getFormAction, changeFormAction, createFormAction, submitFormAction };
