import styled from '@emotion/styled';
import React from 'react';
import TextareaProps from '../types/atoms/textarea';
import { color } from '@/styles/color';

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(({ ...rest }, ref) => (
  <StyledInput
    ref={ref}
    {...rest}
    placeholder={rest.placeholder ? rest.placeholder : '입력해주세요'}
  />
));

Textarea.displayName = 'Textarea';

const StyledInput = styled.textarea<TextareaProps>`
  width: ${(props) => props.width}px;
  border-radius: 8px;
  height: auto;
  min-height: 50px;
  border: 1px solid ${color.grey04};
  background-color: ${color.grey01};
  outline: none;

  padding: 15px;

  color: ${color.black08};
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;

  resize: none;
  overflow-y: hidden;
  &::placeholder {
    color: ${color.grey08};
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
`;

export default Textarea;
