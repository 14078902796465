import { useRoutes } from 'react-router-dom';
import routes from './routes/Routes';
import GlobalStyle from './styles/GlobalStyle';
import { ToastContainer } from 'react-toastify';

function App() {
  const elements = useRoutes(routes);
  return (
    <>
      <GlobalStyle />
      {elements}
      <ToastContainer />
    </>
  );
}

export default App;
