export type ColorType = {
  r: number;
  g: number;
  b: number;
  a?: number;
};

export type ThemeColorListType = {
  outside: ColorType;
  inside: ColorType;
  button: ColorType;
  font: ColorType;
  buttonFont: ColorType;
  border: ColorType;
  element: ColorType;
};

const Custom: ThemeColorListType = {
  outside: { r: 255, g: 255, b: 255, a: 1 },
  inside: { r: 255, g: 255, b: 255, a: 1 },
  button: { r: 249, g: 187, b: 95, a: 1 },
  font: { r: 13, g: 13, b: 13, a: 1 },
  buttonFont: { r: 13, g: 13, b: 13, a: 1 },
  border: { r: 65, g: 65, b: 65, a: 1 },
  element: { r: 13, g: 13, b: 13, a: 1 },
};

const Dark: ThemeColorListType = {
  outside: { r: 13, g: 13, b: 13, a: 1 },
  inside: { r: 13, g: 13, b: 13, a: 1 },
  button: { r: 255, g: 255, b: 255, a: 1 },
  font: { r: 255, g: 255, b: 255, a: 1 },
  buttonFont: { r: 13, g: 13, b: 13, a: 1 },
  border: { r: 65, g: 65, b: 65, a: 1 },
  element: { r: 13, g: 13, b: 13, a: 1 },
};

const PurpleWhite: ThemeColorListType = {
  outside: { r: 220, g: 176, b: 253, a: 1 },
  inside: { r: 220, g: 176, b: 253, a: 1 },
  button: { r: 255, g: 255, b: 255, a: 1 },
  font: { r: 13, g: 13, b: 13, a: 1 },
  buttonFont: { r: 13, g: 13, b: 13, a: 1 },
  border: { r: 144, g: 0, b: 245, a: 1 },
  element: { r: 158, g: 33, b: 230, a: 1 },
};

const RedWhite: ThemeColorListType = {
  outside: { r: 252, g: 108, b: 108, a: 89 },
  inside: { r: 252, g: 108, b: 108, a: 89 },
  button: { r: 255, g: 255, b: 255, a: 1 },
  font: { r: 13, g: 13, b: 13, a: 1 },
  buttonFont: { r: 13, g: 13, b: 13, a: 1 },
  border: { r: 255, g: 255, b: 255, a: 1 },
  element: { r: 231, g: 61, b: 61, a: 1 },
};

const GreenWhite: ThemeColorListType = {
  outside: { r: 125, g: 243, b: 95, a: 67 },
  inside: { r: 125, g: 243, b: 95, a: 67 },
  button: { r: 255, g: 255, b: 255, a: 1 },
  font: { r: 13, g: 13, b: 13, a: 1 },
  buttonFont: { r: 13, g: 13, b: 13, a: 1 },
  border: { r: 255, g: 255, b: 255, a: 1 },
  element: { r: 52, g: 170, b: 20, a: 1 },
};

const BlueWhite: ThemeColorListType = {
  outside: { r: 102, g: 194, b: 248, a: 73 },
  inside: { r: 102, g: 194, b: 248, a: 73 },
  button: { r: 255, g: 255, b: 255, a: 1 },
  font: { r: 13, g: 13, b: 13, a: 1 },
  buttonFont: { r: 13, g: 13, b: 13, a: 1 },
  border: { r: 255, g: 255, b: 255, a: 1 },
  element: { r: 9, g: 121, b: 194, a: 1 },
};

const YellowBlack: ThemeColorListType = {
  outside: { r: 250, g: 245, b: 60, a: 79 },
  inside: { r: 250, g: 245, b: 60, a: 79 },
  button: { r: 13, g: 13, b: 13, a: 1 },
  font: { r: 13, g: 13, b: 13, a: 1 },
  buttonFont: { r: 255, g: 255, b: 255, a: 1 },
  border: { r: 13, g: 13, b: 13, a: 1 },
  element: { r: 13, g: 13, b: 13, a: 1 },
};

const BrownBlack: ThemeColorListType = {
  outside: { r: 197, g: 163, b: 92, a: 83 },
  inside: { r: 197, g: 163, b: 92, a: 83 },
  button: { r: 13, g: 13, b: 13, a: 1 },
  font: { r: 13, g: 13, b: 13, a: 1 },
  buttonFont: { r: 255, g: 255, b: 255, a: 1 },
  border: { r: 13, g: 13, b: 13, a: 1 },
  element: { r: 13, g: 13, b: 13, a: 1 },
};

export { Custom, Dark, PurpleWhite, GreenWhite, RedWhite, BlueWhite, YellowBlack, BrownBlack };
