import styled from '@emotion/styled';
import { SpacingProps } from '@/components/types/atoms/spacing';

const Spacing = ({
  alignItems = 'stretch',
  justifyContent = 'start',
  col = false,
  gap = 0,
  width,
  height,
  children,
}: SpacingProps) => {
  return (
    <StyledSpacing
      gap={gap}
      width={width}
      alignItems={alignItems}
      justifyContent={justifyContent}
      col={col}
      height={height}
    >
      {children}
    </StyledSpacing>
  );
};

const StyledSpacing = styled.div<SpacingProps>`
  display: flex;
  gap: ${(props) => props.gap}px;
  flex-direction: ${(props) => (props.col ? 'column' : 'row')};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;
export default Spacing;
