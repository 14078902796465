interface CheckboxSvgProps {
  isHover: boolean;
  width: number;
  height: number;
  color: string;
  hoverColor: string;
}

const CheckedCheckboxSvg = ({ isHover, width, height, color, hoverColor }: CheckboxSvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="4" fill={isHover ? hoverColor : color} />
      <path
        d="M14.6666 6.79166L8.24992 13.2083L5.33325 10.2917"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckedCheckboxSvg;
