import { createFormAction } from '@/api';
import { color } from '@/styles/color';
import Button from '@components/atoms/Button';
import Spacing from '@components/atoms/Spacing';
import Typography from '@components/atoms/Typography';
import TopArticle from '@components/common/TopArticle';
import PreviwFormList from '@components/form/PreviewFormList';
import ThemeSelector from '@components/form/Selector';
import {
  BlueWhite,
  BrownBlack,
  Custom,
  Dark,
  GreenWhite,
  PurpleWhite,
  RedWhite,
  ThemeColorListType,
  YellowBlack,
} from '@components/form/theme';
import styled from '@emotion/styled';
import { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import { useNavigate, useParams } from 'react-router-dom';

const Background = () => {
  const navigator = useNavigate();
  const params = useParams();

  const [theme, setTheme] = useState<string>('Custom');

  const [os, setOs] = useState<string>('PC');

  const [target, setTarget] = useState<string>('outside');

  const [themeColorList, setThemeColorList] = useState<ThemeColorListType>({
    outside: { r: 255, g: 255, b: 255, a: 1 },
    inside: { r: 255, g: 255, b: 255, a: 1 },
    button: { r: 249, g: 187, b: 95, a: 1 },
    font: { r: 13, g: 13, b: 13, a: 1 },
    buttonFont: { r: 8, g: 8, b: 8, a: 1 },
    border: { r: 65, g: 65, b: 65, a: 1 },
    element: { r: 0, g: 0, b: 0, a: 1 },
  });

  const handleGetColor = () => {
    switch (target) {
      case 'outside':
        return themeColorList.outside;
      case 'inside':
        return themeColorList.inside;
      case 'button':
        return themeColorList.button;
      case 'font':
        return themeColorList.font;
      case 'buttonFont':
        return themeColorList.buttonFont;
      case 'border':
        return themeColorList.border;
      case 'element':
        return themeColorList.element;
    }
  };

  const handleColorChange = (color: ColorResult) => {
    switch (target) {
      case 'outside':
        setThemeColorList({
          ...themeColorList,
          outside: { r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a },
        });
        break;
      case 'inside':
        setThemeColorList({
          ...themeColorList,
          inside: { r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a },
        });
        break;
      case 'button':
        setThemeColorList({
          ...themeColorList,
          button: { r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a },
        });
        break;
      case 'font':
        setThemeColorList({
          ...themeColorList,
          font: { r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a },
        });
        break;
      case 'buttonFont':
        setThemeColorList({
          ...themeColorList,
          buttonFont: { r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a },
        });
        break;
      case 'border':
        setThemeColorList({
          ...themeColorList,
          border: { r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a },
        });
        break;
      case 'element':
        setThemeColorList({
          ...themeColorList,
          element: { r: color.rgb.r, g: color.rgb.g, b: color.rgb.b, a: color.rgb.a },
        });
        break;
    }
  };

  const handleChangeTarget = (value: string) => {
    setTarget(value);
  };

  const handleChangeOs = (value: string) => {
    setOs(value);
  };

  const handleChangeTheme = (value: string) => {
    setTheme(value);

    if (value === 'Custom') {
      setThemeColorList(Custom);
      return;
    }

    if (value === 'Dark') {
      setThemeColorList(Dark);
      return;
    }

    if (value === 'PurpleWhite') {
      setThemeColorList(PurpleWhite);
      return;
    }

    if (value === 'GreenWhite') {
      setThemeColorList(GreenWhite);
      return;
    }

    if (value === 'RedWhite') {
      setThemeColorList(RedWhite);
      return;
    }

    if (value === 'BlueWhite') {
      setThemeColorList(BlueWhite);
      return;
    }

    if (value === 'YellowBlack') {
      setThemeColorList(YellowBlack);
      return;
    }

    if (value === 'BrownBlack') {
      setThemeColorList(BrownBlack);
      return;
    }
  };

  const createForm = async () => {
    try {
      await createFormAction(params.id, themeColorList);

      navigator(`/complete/${params.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box>
      <Spacing col alignItems="center" gap={60}>
        <TopArticle title="원하시는 색상을 선택해주세요!" isSubTitle />
        <Spacing gap={20}>
          <Spacing col gap={12} alignItems="center">
            <Typography color={color.black04} size={28} weight={700} height={42}>
              {theme}
            </Typography>
            <PreviwFormList colorList={themeColorList} os={os} />
          </Spacing>
          <Spacing col gap={12} alignItems="center">
            <Typography color={color.black04} size={28} weight={600} height={42}>
              테마
            </Typography>

            <ThemeBox>
              <Spacing col gap={8} alignItems="center">
                <Typography color={color.black04} size={20} weight={600} height={24}>
                  미리보기
                </Typography>
                <Spacing gap={10}>
                  <Button
                    onClick={() => handleChangeOs('PC')}
                    borderRadius="8px"
                    border={`1px solid ${color.grey07}`}
                    backgroundColor={os === 'PC' ? color.primary : color.white}
                    font={{ size: 14, weight: 700, color: color.black }}
                  >
                    PC
                  </Button>
                  <Button
                    onClick={() => handleChangeOs('Mobile')}
                    borderRadius="8px"
                    border={`1px solid ${color.grey07}`}
                    backgroundColor={os === 'Mobile' ? color.primary : color.white}
                    font={{ size: 14, weight: 700, color: color.black }}
                  >
                    Mobile
                  </Button>
                </Spacing>
              </Spacing>
              <Spacing col gap={8} alignItems="center">
                <Typography color={color.black04} size={20} weight={600} height={24}>
                  템플릿
                </Typography>
                <ThemeSelector theme={theme} onClick={handleChangeTheme} />
              </Spacing>
              <Spacing col gap={8} alignItems="center">
                <Typography color={color.black04} size={20} weight={600} height={24}>
                  배경
                </Typography>
                <Spacing gap={10}>
                  <Button
                    onClick={() => handleChangeTarget('outside')}
                    borderRadius="8px"
                    border={`1px solid ${color.grey07}`}
                    backgroundColor={target === 'outside' ? color.primary : color.white}
                    font={{ size: 14, weight: 700, color: color.black }}
                  >
                    Outside
                  </Button>
                  <Button
                    onClick={() => handleChangeTarget('inside')}
                    borderRadius="8px"
                    border={`1px solid ${color.grey07}`}
                    backgroundColor={target === 'inside' ? color.primary : color.white}
                    font={{ size: 14, weight: 700, color: color.black }}
                  >
                    Inside
                  </Button>
                </Spacing>
                <Spacing gap={10}>
                  <Button
                    onClick={() => handleChangeTarget('border')}
                    borderRadius="8px"
                    border={`1px solid ${color.grey07}`}
                    backgroundColor={target === 'border' ? color.primary : color.white}
                    font={{ size: 14, weight: 700, color: color.black }}
                  >
                    Border
                  </Button>
                </Spacing>
              </Spacing>
              <Spacing col gap={8} alignItems="center">
                <Typography color={color.black04} size={20} weight={600} height={24}>
                  아이템
                </Typography>
                <Spacing gap={10}>
                  <Button
                    onClick={() => handleChangeTarget('font')}
                    borderRadius="8px"
                    border={`1px solid ${color.grey07}`}
                    backgroundColor={target === 'font' ? color.primary : color.white}
                    font={{ size: 14, weight: 700, color: color.black }}
                  >
                    Font
                  </Button>
                  <Button
                    onClick={() => handleChangeTarget('element')}
                    borderRadius="8px"
                    border={`1px solid ${color.grey07}`}
                    backgroundColor={target === 'element' ? color.primary : color.white}
                    font={{ size: 14, weight: 700, color: color.black }}
                  >
                    Element
                  </Button>
                </Spacing>
              </Spacing>
              <Spacing col gap={8} alignItems="center">
                <Typography color={color.black04} size={20} weight={600} height={24}>
                  버튼
                </Typography>
                <Spacing gap={10}>
                  <Button
                    onClick={() => handleChangeTarget('button')}
                    borderRadius="8px"
                    border={`1px solid ${color.grey07}`}
                    backgroundColor={target === 'button' ? color.primary : color.white}
                    font={{ size: 14, weight: 700, color: color.black }}
                  >
                    Button
                  </Button>
                  <Button
                    onClick={() => handleChangeTarget('buttonFont')}
                    borderRadius="8px"
                    border={`1px solid ${color.grey07}`}
                    backgroundColor={target === 'buttonFont' ? color.primary : color.white}
                    font={{ size: 14, weight: 700, color: color.black }}
                  >
                    Button Font
                  </Button>
                </Spacing>
              </Spacing>
              <SketchPicker
                color={handleGetColor()}
                onChange={(color: ColorResult) => handleColorChange(color)}
              />
            </ThemeBox>
          </Spacing>
        </Spacing>
        <Button
          onClick={createForm}
          backgroundColor={color.primary}
          borderRadius="6px"
          padding="8px 24px"
          font={{ color: color.white, size: 24, weight: 700, height: 33.6 }}
        >
          생성하기
        </Button>
      </Spacing>
    </Box>
  );
};

const Box = styled.section`
  width: 1440px;
`;

const ThemeBox = styled.section`
  display: flex;
  flex-direction: column;
  gap: 22px;
  align-items: center;
  border: 1px solid ${color.grey06};
  border-radius: 8px;
  padding: 20px;
  background-color: ${color.white};
`;

export default Background;
