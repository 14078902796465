import Logo from '@/assets/logo.svg';
import { color } from '@/styles/color';
import Typography from '@components/atoms/Typography';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigator = useNavigate();
  return (
    <Box>
      <Container>
        <LeftArea>
          <NameWrapper onClick={() => navigator('/')}>
            <img src={Logo} width={40} height={40} />
            <Typography color={color.tertiary} size={24} weight={700} height={33.6}>
              Formark
            </Typography>
          </NameWrapper>
        </LeftArea>
      </Container>
    </Box>
  );
};

const Box = styled.header`
  border-bottom: 1px solid ${color.grey04};
`;

const Container = styled.div`
  display: flex;
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1280px;
  height: 70px;
  justify-content: space-between;
  align-items: center;
`;

const LeftArea = styled.div`
  display: flex;
  gap: 80px;
  align-items: center;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;
export default Header;
