import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import RequiredStar from '@/assets/required_star.svg';
import Textarea from '@components/atoms/Textarea';
import Typography from '@components/atoms/Typography';
import { ElementType, ParagraphType } from '@components/types/\bform';
import { useRef, useState } from 'react';
import { updateOrAddData } from '../utils';

interface ParagraphElementProps extends Omit<ElementType, 'data'> {
  data: ParagraphType;
}

const ParagraphElement = ({
  data,
  colorList,
  responseData,
  setResponseData,
}: ParagraphElementProps) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [value, setValue] = useState<string>('');

  const handleChangeContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    // textarea 높이 조절

    updateOrAddData(responseData, setResponseData, {
      id: data.id.toString(),
      value: e.target.value,
    });
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height = scrollHeight + 'px';
    }
  };

  return (
    <Spacing col gap={25}>
      <Spacing justifyContent="space-between" alignItems="center">
        <Spacing gap={3} alignItems="center">
          {data.required === 1 && <img src={RequiredStar} width={12} height={12} />}
          <Typography color={getRgbaText(colorList.font)} size={16} weight={700} height={26}>
            {data.question}
          </Typography>
        </Spacing>
      </Spacing>
      <Textarea
        ref={textareaRef}
        onChange={handleChangeContent}
        value={
          responseData.filter((item) => item.id === data.id.toString())[0]
            ? responseData.filter((item) => item.id === data.id.toString())[0].value
            : value
        }
      />
    </Spacing>
  );
};

export default ParagraphElement;
