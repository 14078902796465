import { getRgbaText } from '@/utils';
import Dropdown from '@components/atoms/Drodown';
import Spacing from '@components/atoms/Spacing';
import RequiredStar from '@/assets/required_star.svg';
import Typography from '@components/atoms/Typography';
import { DropdownType, ElementType } from '@components/types/\bform';
import { useState } from 'react';
import { updateOrAddData } from '../utils';

interface DropdownElementProps extends Omit<ElementType, 'data'> {
  data: DropdownType;
}

interface DropdownValueListProps {
  valueList: string[];
  currentValue: string;
  id: number;
}

const DropdownElement = ({
  data,
  colorList,
  responseData,
  setResponseData,
}: DropdownElementProps) => {
  const [dropdownValueList, setDropdownValueList] = useState<DropdownValueListProps>({
    valueList: ['선택', ...data.choices],
    currentValue: '선택',
    id: data.id,
  });

  const handleChangeDropdownValue = (value: string) => {
    setDropdownValueList({ ...dropdownValueList, currentValue: value });

    updateOrAddData(responseData, setResponseData, {
      id: data.id.toString(),
      value: value,
    });
  };

  const getDropdownValue = () => {
    if (responseData.length === 0) {
      return dropdownValueList.currentValue;
    } else {
      if (responseData.filter((item) => item.id === data.id.toString()).length > 0) {
        return responseData.filter((item) => item.id === data.id.toString())[0].value;
      } else {
        return dropdownValueList.currentValue;
      }
    }
  };

  return (
    <Spacing col gap={25}>
      <Spacing justifyContent="space-between" alignItems="center">
        <Spacing gap={3} alignItems="center">
          {data.required === 1 && <img src={RequiredStar} width={12} height={12} />}
          <Typography color={getRgbaText(colorList.font)} size={16} weight={700} height={26}>
            {data.question}
          </Typography>
        </Spacing>
      </Spacing>
      <Typography color={getRgbaText(colorList.font)} size={18} weight={500} height={16}>
        <Dropdown
          height={43}
          placeholder="은행"
          optionList={dropdownValueList.valueList}
          value={getDropdownValue()}
          onChange={handleChangeDropdownValue}
          style={{ border: '1px solid #707683', borderRadius: '6px' }}
        />
      </Typography>
    </Spacing>
  );
};

export default DropdownElement;
