import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import RequiredStar from '@/assets/required_star.svg';
import Typography from '@components/atoms/Typography';
import { DateType, ElementType } from '@components/types/\bform';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { updateOrAddData } from '../utils';

interface DateElementProps extends Omit<ElementType, 'data'> {
  data: DateType;
}

const DateElement = ({ data, colorList, responseData, setResponseData }: DateElementProps) => {
  const [date, setDate] = useState(new Date());

  const handleChangeDate = (date: Date) => {
    setDate(date);

    updateOrAddData(responseData, setResponseData, {
      id: `${data.id.toString()}_year`,
      value: date.getFullYear().toString(),
    });

    updateOrAddData(responseData, setResponseData, {
      id: `${data.id.toString()}_month`,
      value: date.getMonth().toString(),
    });

    updateOrAddData(responseData, setResponseData, {
      id: `${data.id.toString()}_day`,
      value: date.getDay().toString(),
    });
  };
  return (
    <Spacing col gap={25}>
      <Spacing justifyContent="space-between" alignItems="center">
        <Spacing gap={3} alignItems="center">
          {data.required === 1 && <img src={RequiredStar} width={12} height={12} />}
          <Typography color={getRgbaText(colorList.font)} size={16} weight={700} height={26}>
            {data.title}
          </Typography>
        </Spacing>
      </Spacing>
      <ReactDatePicker showIcon selected={date} onChange={handleChangeDate} />
    </Spacing>
  );
};

export default DateElement;
