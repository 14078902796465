interface RadioSvgProps {
  isHover: boolean;
  width: number;
  height: number;
  color: string;
  hoverColor: string;
}

const CheckedRadioSvg = ({ isHover, width, height, color, hoverColor }: RadioSvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20"
    >
      <circle cx="10" cy="10" r="9.5" stroke={isHover ? hoverColor : color} />
      <circle cx="10" cy="10" r="5" fill={isHover ? hoverColor : color} />
    </svg>
  );
};

export default CheckedRadioSvg;
