import { color } from '@/styles/color';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface ColorIconProps {
  onClick: () => void;
  isSelected: boolean;
  leftColor: string;
  rightColor: string;
}

const ColorIcon = ({ onClick, isSelected, leftColor, rightColor }: ColorIconProps) => {
  return (
    <Box onClick={onClick} isSelected={isSelected}>
      <LeftContainer value={leftColor} />
      <RightContainer value={rightColor} />
    </Box>
  );
};

const Box = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  width: 30px;
  height: 30px;
  border: 2px solid ${color.grey06};
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      border: 2px solid ${color.black};
    `}

  &:hover {
    background-color: white;
  }
`;

const LeftContainer = styled.div<{ value: string }>`
  width: 15px;
  height: 30px;
  border-radius: 9px 0 0 9px;
  background-color: ${(props) => props.value};
`;

const RightContainer = styled.div<{ value: string }>`
  width: 15px;
  height: 30px;
  border-radius: 0 9px 9px 0;
  background-color: ${(props) => props.value};
`;

export default ColorIcon;
