import { color } from '@/styles/color';
import Spacing from '@components/atoms/Spacing';
import Typography from '@components/atoms/Typography';
import TopArticle from '@components/common/TopArticle';
import styled from '@emotion/styled';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate, useParams } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';

const Complete = () => {
  const params = useParams();
  const navigator = useNavigate();

  return (
    <Box>
      <Spacing col alignItems="center" gap={60}>
        <TopArticle title="설문지 생성이 완료되었습니다!" isSubTitle />
        <Spacing gap={12}>
          <Card onClick={() => navigator(`/form/${params.id}`)}>
            <UrlText>바로가기</UrlText>
          </Card>
          <CopyToClipboard
            text={`${window.location.origin}/form/${params.id}`}
            onCopy={() =>
              toast.success('Copied', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
              })
            }
          >
            <Card>
              <Typography cur size={20} weight={700} height={24} color={color.primary}>
                URL 복사
              </Typography>
            </Card>
          </CopyToClipboard>
        </Spacing>
      </Spacing>
    </Box>
  );
};

const Box = styled.section`
  width: 1440px;
  height: calc(100vh - 300px);
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  padding: 20px;
  background-color: ${color.white};
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
`;

const UrlText = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: ${color.tertiary};
  line-height: 24p;
  text-decoration: underline;
`;

export default Complete;
