import { color } from '@/styles/color';
import styled from '@emotion/styled';
import { useState } from 'react';
import Spacing from '../Spacing';
import CheckedRadioSvg from './CheckedRadioSvg';
import UnCheckedRadioSvg from './UnCheckedRadioSvg';
import { calculateHoverColor } from '@/utils';
import { css } from '@emotion/react';

interface RadioProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  id: string;
  value: string;
  color: string;
  width?: string;
}

const Radio = ({ label, checked, onChange, id, value, color, width }: RadioProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  return (
    <Box onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} width={width}>
      <StyledRadioInput type="radio" checked={checked} onChange={onChange} id={id} name={value} />
      <StyeldRadioLabel htmlFor={id}>
        <Spacing gap={8} alignItems="center">
          {checked ? (
            <CheckedRadioSvg
              width={20}
              height={20}
              color={color}
              hoverColor={calculateHoverColor(color, isHover)}
              isHover={isHover}
            />
          ) : (
            <UnCheckedRadioSvg
              width={20}
              height={20}
              color={color}
              hoverColor={calculateHoverColor(color, isHover)}
              isHover={isHover}
            />
          )}

          {label}
        </Spacing>
      </StyeldRadioLabel>
    </Box>
  );
};

const Box = styled.div<{ width: string }>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
`;

const StyledRadioInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;

  :hover {
    cursor: pointer;
  }

  &:checked + label {
    background-color: ${color.grey01};
    border: 1px solid ${color.grey07};
  }

  width: 200px;
  height: 30px;
`;

const StyeldRadioLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 3px 15px;
  width: 100%;
  height: 36px;

  border: 1px solid ${color.grey04};

  border-radius: 8px;
  background-color: ${color.grey01};
  outline: none;
  box-sizing: border-box;

  color: ${color.black08};
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;

  white-space: nowrap;
  :hover {
    cursor: pointer;
    background-color: ${color.grey02};
  }

  :hover > svg {
    cursor: pointer;
  }
`;

export default Radio;
