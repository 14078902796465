import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import RequiredStar from '@/assets/required_star.svg';
import Typography from '@components/atoms/Typography';
import { DescType, ElementType } from '@components/types/\bform';

interface DescEelemtnProps extends Omit<ElementType, 'data'> {
  data: DescType;
}

const DescElement = ({ data, colorList }: DescEelemtnProps) => {
  return (
    <Spacing col gap={25}>
      <Spacing justifyContent="space-between" alignItems="center">
        <Spacing gap={3} alignItems="center">
          {data.required === 1 && <img src={RequiredStar} width={12} height={12} />}
          <Typography color={getRgbaText(colorList.font)} size={16} weight={700} height={26}>
            {data.desc}
          </Typography>
        </Spacing>
      </Spacing>
      <Typography color={getRgbaText(colorList.font)} size={18} weight={500} height={16}>
        {data.sub_desc}
      </Typography>
    </Spacing>
  );
};

export default DescElement;
