import { color } from '@/styles/color';
import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import Typography from '@components/atoms/Typography';
import { FormDataResponseType, SurveyType } from '@components/types/\bform';
import styled from '@emotion/styled';
import { SetStateAction } from 'react';
import FormCard from './FormCard';
import { ThemeColorListType } from './theme';

interface FormListProps {
  colorList: ThemeColorListType;
  formData: SurveyType;
  responseData: FormDataResponseType[];
  setResponseData: React.Dispatch<SetStateAction<FormDataResponseType[]>>;
  os?: string;
}

const FormList = ({
  colorList,
  formData,
  responseData,
  setResponseData,
  os = 'PC',
}: FormListProps) => {
  return (
    <Box color={getRgbaText(colorList.outside)}>
      <ProfileBox>
        <Spacing col gap={50} alignItems="center">
          <Typography color={getRgbaText(colorList.font)} size={24} weight={700} height={32}>
            {formData.metadata.title}
          </Typography>
          <Typography
            color={getRgbaText(colorList.font)}
            size={14}
            weight={500}
            whiteSpace="pre-wrap"
          >
            {formData.metadata.desc}
          </Typography>
        </Spacing>
      </ProfileBox>
      <Spacing col width="100%" gap={12} alignItems="center">
        {formData.form.map((data) => {
          return (
            <FormCard
              key={Array.isArray(data) ? data.length : data.id}
              colorList={colorList}
              os={os}
              data={data}
              responseData={responseData}
              setResponseData={setResponseData}
            />
          );
        })}
      </Spacing>
    </Box>
  );
};

const Box = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 40px;
  width: 850px;

  justify-content: center;

  @media screen and (min-width: 850px) {
    border: 1px solid ${color.grey06};
  }

  @media screen and (max-width: 850px) {
    width: 750px;
  }

  @media screen and (max-width: 750px) {
    width: 650px;
  }

  @media screen and (max-width: 650px) {
    width: 550px;
  }

  @media screen and (max-width: 550px) {
    width: 450px;
  }
  @media screen and (max-width: 450px) {
    width: 350px;
  }

  @media screen and (max-width: 391px) {
    width: 300px;
  }

  @media screen and (max-width: 350px) {
    width: 250px;
  }

  border-radius: 8px;

  background-color: ${(props) => props.color};
`;

const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export default FormList;
