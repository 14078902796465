import { getFormAction, submitFormAction } from '@/api';
import { color } from '@/styles/color';
import { getRgbaText } from '@/utils';
import Button from '@components/atoms/Button';
import Spacing from '@components/atoms/Spacing';
import FormList from '@components/form/FormList';
import { Custom, ThemeColorListType } from '@components/form/theme';
import {
  FormDataRequestType,
  FormDataResponseType,
  GridCellType,
  SurveyType,
} from '@components/types/\bform';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';

const Form = () => {
  const navigator = useNavigate();
  const params = useParams();
  const [formData, setFormData] = useState<SurveyType>({
    form: [],
    id: '',
    metadata: { desc: '', form_id: '', name: '', section_count: 0, title: '' },
    orgin: '',
    theme: { theme: Custom },
  });

  const [colorList, setColorList] = useState<ThemeColorListType>(Custom);
  const [requiredIdList, setRequiredIdList] = useState<string[]>([]);

  const [responseData, setResponseData] = useState<FormDataResponseType[]>([]);

  const handleGetForm = async () => {
    try {
      const response: FormDataRequestType = await getFormAction(params.id);

      setFormData(response.data);
      setColorList(response.data.theme.theme);

      setRequiredIdList(
        response.data.form
          .filter((item) => item.required)
          .map((requiredItem) => requiredItem.id.toString())
      );

      const idList = response.data.form
        .map((item) =>
          item.type === 'section'
            ? item.children.filter((ele) => ele.required)
            : Array.isArray(item) && item.filter((ele) => ele.required)
        )
        .map((reqList) =>
          reqList.map((req) => {
            if (req.type === 'grid') {
              // 그리드의 id만 추출
              return req.grid.map((gridItem: GridCellType) => gridItem.id);
            }

            // 그리드가 아닌 경우
            return req.id;
          })
        )
        .flat()
        .flat(); // 중첩된 배열을 평탄화
      const tempArr: string[] = [];
      idList.map((item) => {
        tempArr.push(item);
      });

      setRequiredIdList(tempArr);
    } catch (error) {
      console.error(error);
    }
  };

  const getCheckRequiredValue = () => {
    return requiredIdList
      .map((item) => responseData.filter((ele) => ele.id === item.toString()).length)
      .includes(0);
  };

  const submitForm = async () => {
    if (getCheckRequiredValue()) {
      toast.error('Please check the required value', {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      return;
    }

    try {
      await submitFormAction(params.id, responseData);

      navigator(`/submit/${params.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetForm();
  }, []);
  const width = window.innerWidth;

  return (
    <Container backgroundColor={getRgbaText(colorList.outside)}>
      <Spacing col gap={20} alignItems="center">
        <FormList
          os={width > 1024 ? 'PC' : 'Mobile'}
          colorList={colorList}
          formData={formData}
          responseData={responseData}
          setResponseData={setResponseData}
        />

        {formData.form.length > 0 && (
          <Button
            onClick={submitForm}
            padding="8px 40px"
            borderRadius="8px"
            border={`1px solid ${color.grey06}`}
            backgroundColor={getCheckRequiredValue() ? color.grey06 : getRgbaText(colorList.button)}
            font={{
              size: 18,
              color: getRgbaText(colorList.buttonFont),
              weight: 600,
              height: 18,
            }}
          >
            제출
          </Button>
        )}
      </Spacing>
    </Container>
  );
};

const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};

  width: 100%;
  height: 100%;
  @media screen and (min-height: 1350px) {
    height: 100vh;
  }

  padding: 30px;
  box-sizing: border-box;
`;

export default Form;
