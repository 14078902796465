import {
  CheckboxType,
  DateType,
  DescType,
  DropdownType,
  FormDataResponseType,
  FormType,
  GridType,
  LinearType,
  ParagraphType,
  RadioType,
  ShortAnswerType,
  TimeType,
} from '@components/types/\bform';
import CheckboxElement from './element/CheckboxElement';
import DateElement from './element/DateElement';
import DescElement from './element/DescElement';
import DropdownElement from './element/DropdownElement';
import GridElement from './element/GridElement';
import LinearElement from './element/LinearElement';
import ParagraphElement from './element/ParagraphElement';
import RadioElement from './element/RadioElement';
import ShortAnswerElement from './element/ShortAnswerElement';
import TimeElement from './element/TimeElement';
import { ThemeColorListType } from './theme';
import { SetStateAction } from 'react';

const getFormCardElement = (
  type: string,
  typeData: FormType,
  colorList: ThemeColorListType,
  responseData: FormDataResponseType[],
  setResponseData: React.Dispatch<SetStateAction<FormDataResponseType[]>>
) => {
  switch (type) {
    case 'desc':
      return (
        <DescElement
          data={typeData as DescType}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      );
    case 'radio':
      return (
        <RadioElement
          data={typeData as RadioType}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      );
    case 'checkbox':
      return (
        <CheckboxElement
          data={typeData as CheckboxType}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      );
    case 'dropdown':
      return (
        <DropdownElement
          data={typeData as DropdownType}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      );
    case 'linear':
      return (
        <LinearElement
          data={typeData as LinearType}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      );
    case 'grid':
      return (
        <GridElement
          data={typeData as GridType}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      );
    case 'date':
      return (
        <DateElement
          data={typeData as DateType}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      );
    case 'time':
      return (
        <TimeElement
          data={typeData as TimeType}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      );
    case 'short_answer':
      return (
        <ShortAnswerElement
          data={typeData as ShortAnswerType}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      );
    case 'paragraph':
      return (
        <ParagraphElement
          data={typeData as ParagraphType}
          colorList={colorList}
          responseData={responseData}
          setResponseData={setResponseData}
        />
      );
    default:
      return <div>1</div>;
  }
};

export { getFormCardElement };
