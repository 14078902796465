import { changeFormAction } from '@/api';
import { ChangeFormResponseType } from '@/api/type';
import { color } from '@/styles/color';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../atoms/Button';
import Input from '../atoms/Input';
import Spacing from '../atoms/Spacing';
import styled from '@emotion/styled';

const SearchBar = () => {
  const navigator = useNavigate();

  const [formUrl, setFormUrl] = useState<string>('');

  const handleChangeForm = async () => {
    try {
      const response: ChangeFormResponseType = await changeFormAction(formUrl);

      navigator(`theme/${response.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeFormUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormUrl(e.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleChangeForm();
    }
  };

  return (
    <Spacing>
      <StyledInput
        value={formUrl}
        onChange={handleChangeFormUrl}
        border={`1px solid ${color.primary}`}
        placeholder="https://forms.gle/..."
        onKeyDown={handleKeyDown}
        style={{ borderRight: 'none', borderRadius: '6px 0 0 6px' }}
      />
      <Button
        onClick={handleChangeForm}
        border={`1px solid ${color.secondary}`}
        borderRadius="0 6px 6px 0"
        backgroundColor={color.primary}
        padding="0 28px"
        font={{ color: color.white, size: 22, weight: 700, height: 30.8 }}
      >
        변환
      </Button>
    </Spacing>
  );
};

const StyledInput = styled(Input)`
  height: 60px;
  width: 500px;
  @media screen and (max-width: 700px) {
    width: 500px;
  }

  @media screen and (max-width: 600px) {
    width: 400px;
  }

  @media screen and (max-width: 500px) {
    width: 300px;
  }

  @media screen and (max-width: 400px) {
    width: 200px;
  }
`;

export default SearchBar;
