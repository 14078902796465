import { TypographyProps } from '@/components/types/atoms/typography';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Typography = ({
  size = 16,
  color = '#000',
  height = 'normal',
  weight = 400,
  letterSpacing = 'normal',
  wordSpacing = 'normal',
  cur = false,
  isDrag = true,
  whiteSpace = 'normal',
  onClick,
  children,
}: TypographyProps) => {
  return (
    <StyledTypography
      size={size}
      color={color}
      height={height}
      weight={weight}
      letterSpacing={letterSpacing}
      wordSpacing={wordSpacing}
      cur={cur}
      isDrag={isDrag}
      whiteSpace={whiteSpace}
      onClick={onClick}
    >
      {children}
    </StyledTypography>
  );
};

const StyledTypography = styled.span<TypographyProps>`
  font-size: ${(props) => (typeof props.size === 'number' ? props.size + 'px' : props.size)};
  color: ${(props) => props.color};
  line-height: ${(props) =>
    typeof props.height === 'number' ? props.height + 'px' : props.height};
  font-weight: ${(props) => props.weight};
  letter-spacing: ${(props) =>
    typeof props.letterSpacing === 'number' ? props.letterSpacing + 'px' : props.letterSpacing};
  word-spacing: ${(props) =>
    typeof props.wordSpacing === 'number' ? props.wordSpacing + 'px' : props.wordSpacing};

  white-space: ${(props) => props.whiteSpace};

  cursor: ${(props) => (props.cur ? 'pointer' : 'default')};
  ${(props) =>
    !props.isDrag &&
    css`
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    `}
`;

export default Typography;
