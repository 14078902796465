import User from '@/assets/user.svg';
import { color } from '@/styles/color';
import Spacing from '@components/atoms/Spacing';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ColorIcon from './ColorIcon';

interface ThemeSelectorProps {
  theme: string;
  onClick: (theme: string) => void;
}

const ThemeSelector = ({ theme, onClick }: ThemeSelectorProps) => {
  return (
    <Box>
      <Spacing col gap={8} alignItems="center">
        <Spacing gap={10}>
          <IconBox onClick={() => onClick('Custom')} isSelected={theme === 'Custom'}>
            <img src={User} width={24} height={24} />
          </IconBox>
          <ColorIcon
            onClick={() => onClick('Dark')}
            leftColor={color.black}
            rightColor={color.black}
            isSelected={theme === 'Dark'}
          />
          <ColorIcon
            onClick={() => onClick('PurpleWhite')}
            leftColor={color.purple}
            rightColor={color.white}
            isSelected={theme === 'PurpleWhite'}
          />
        </Spacing>
        <Spacing gap={10}>
          <ColorIcon
            onClick={() => onClick('GreenWhite')}
            leftColor={color.green}
            rightColor={color.white}
            isSelected={theme === 'GreenWhite'}
          />
          <ColorIcon
            onClick={() => onClick('BlueWhite')}
            leftColor={color.blue}
            rightColor={color.white}
            isSelected={theme === 'BlueWhite'}
          />
          <ColorIcon
            onClick={() => onClick('RedWhite')}
            leftColor={color.red}
            rightColor={color.white}
            isSelected={theme === 'RedWhite'}
          />
        </Spacing>
        <Spacing gap={10}>
          <ColorIcon
            onClick={() => onClick('YellowBlack')}
            leftColor={color.yellow}
            rightColor={color.black}
            isSelected={theme === 'YellowBlack'}
          />
          <ColorIcon
            onClick={() => onClick('BrownBlack')}
            leftColor={color.brown}
            rightColor={color.black}
            isSelected={theme === 'BrownBlack'}
          />
        </Spacing>
      </Spacing>
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  gap: 30px;
`;

const IconBox = styled.div<{ isSelected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  border: 1px solid ${color.black};
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${color.tertiary};
    `}

  &:hover {
    background-color: white;
  }
`;
export default ThemeSelector;
