import Background from '@/pages/theme';
import Main from '@/pages/main';
import Layout from '@components/layouts/Layout';
import { RouteObject } from 'react-router-dom';
import Form from '@/pages/form';
import Complete from '@/pages/complete';
import Submit from '@/pages/submit';

const routes: RouteObject[] = [
  {
    path: '',
    children: [
      {
        path: '',
        element: <Layout />,
        children: [
          { path: '', element: <Main /> },
          { path: 'theme/:id', element: <Background /> },
          { path: 'complete/:id', element: <Complete /> },
        ],
      },
      {
        path: '',
        children: [
          { path: 'form/:id', element: <Form /> },
          { path: 'submit/:id', element: <Submit /> },
        ],
      },
    ],
  },
];

export default routes;
