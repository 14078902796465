import { color } from '@/styles/color';
import Input from '@components/atoms/Input';
import InputProps from '@components/types/atoms/input';
import styled from '@emotion/styled';

const BlackWhiteInput = ({ ...props }: InputProps) => {
  return (
    <StyledInput
      value={props.value}
      placeholder={props.placeholder ? props.placeholder : '입력해주세요'}
      onChange={props.onChange ? props.onChange : () => {}}
    />
  );
};

const StyledInput = styled(Input)<InputProps>`
  width: 100%;
  height: 36px;
  border-radius: 8px;
  border: 1px solid ${color.grey04};
  background-color: ${color.grey01};
  outline: none;

  padding: 3px 15px;

  color: ${color.black08};
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;

  :focus {
    border: 1px solid ${color.grey07};
    background-color: ${color.grey02};
  }

  &::placeholder {
    color: ${color.grey08};
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
  }
`;

export default BlackWhiteInput;
