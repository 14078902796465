import { color } from '@/styles/color';
import Spacing from '@components/atoms/Spacing';
import Typography from '@components/atoms/Typography';
import styled from '@emotion/styled';

interface TopArticleProps {
  title: string;
  isSubTitle?: boolean;
  fontColor?: string;
}

const TopArticle = ({ title, isSubTitle, fontColor }: TopArticleProps) => {
  return (
    <Box>
      <Spacing col gap={10} alignItems="center">
        <Typography
          color={fontColor ? fontColor : color.black04}
          size={40}
          weight={700}
          height={48}
        >
          {title}
        </Typography>
        {isSubTitle && (
          <Typography
            color={fontColor ? fontColor : color.black04}
            size={30}
            weight={600}
            height={42}
          >
            1분만에 만드는 커스텀폼
          </Typography>
        )}
      </Spacing>
    </Box>
  );
};

const Box = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
export default TopArticle;
