import { useState, useEffect, useRef } from 'react';

/* click 영역을 확인할 수 있는 hook입니다.
 * isClickInsideArea === true => ref를 지정한 컴포넌트 영역을 클릭했을 경우
 * isClickInsideArea === false => ref를 지정한 컴포넌트 영역 밖을 클릭했을 경우
 */
const useClickOutside = <T extends HTMLElement>() => {
  const [isClickInsideArea, setIsClickInsideArea] = useState<boolean>(true);
  const ref = useRef<T>(null);

  const handleClickOutside = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      setIsClickInsideArea(false);
      return;
    }
    setIsClickInsideArea(true);
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isClickInsideArea, setIsClickInsideArea };
};

export default useClickOutside;
