import axios, { AxiosError, AxiosResponse } from 'axios';

interface AxiosWrapper {
  get<T>(url: string, params?: object): Promise<T>;
  post<T>(url: string, data?: object): Promise<T>;
  put<T>(url: string, data?: object): Promise<T>;
  delete<T>(url: string): Promise<T>;
}

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  timeout: 3000,
});

const handleError = (error: AxiosError): void => {
  console.error('Axios Error:', error);
  throw error;
};

const axiosWrapper: AxiosWrapper = {
  get: <T>(url: string, params?: object): Promise<T> => {
    try {
      const response: Promise<T> = axiosInstance.get(url, { params });
      return response;
    } catch (error: unknown) {
      handleError(error as AxiosError);
      throw error;
    }
  },

  post: async <T>(url: string, data?: object): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await axiosInstance.post(url, data);
      return response.data;
    } catch (error: unknown) {
      handleError(error as AxiosError);
      throw error;
    }
  },

  put: async <T>(url: string, data?: object): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await axiosInstance.put(url, data);
      return response.data;
    } catch (error: unknown) {
      handleError(error as AxiosError);
      throw error;
    }
  },

  delete: async <T>(url: string): Promise<T> => {
    try {
      const response: AxiosResponse<T> = await axiosInstance.delete(url);
      return response.data;
    } catch (error: unknown) {
      handleError(error as AxiosError);
      throw error;
    }
  },
};

export default axiosWrapper;
