import Spacing from '@components/atoms/Spacing';
import TopArticle from '@components/common/TopArticle';
import SearchBar from '@components/main/SearchBar';
import styled from '@emotion/styled';

const Main = () => {
  return (
    <Box>
      <Spacing col alignItems="center" gap={10}>
        <TopArticle title="간편하게 커스텀폼을 만들어 보세요!" isSubTitle />
        <SearchBar />
      </Spacing>
    </Box>
  );
};

const Box = styled.section`
  width: 1440px;

  @media screen and (min-width: 500px) {
    width: 100%;
  }

  height: calc(100vh - 200px);
  /* height: calc(100vh - 300px); */
`;

export default Main;
