interface CheckboxSvgProps {
  isHover: boolean;
  width: number;
  height: number;
  color: string;
  hoverColor: string;
}

const UnCheckedCheckboxSvg = ({ isHover, width, height, color, hoverColor }: CheckboxSvgProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="3.5" stroke={isHover ? hoverColor : color} />
    </svg>
  );
};

export default UnCheckedCheckboxSvg;
