import { color } from '@/styles/color';
import styled from '@emotion/styled';
import { useState } from 'react';
import Spacing from '../Spacing';
import CheckedCheckboxSvg from './CheckedCheckboxSvg';
import UnCheckedCheckboxSvg from './UnCheckedCheckboxSvg';
import { calculateHoverColor } from '@/utils';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: () => void;
  id: string;
  value: string;
  color: string;
}

const Checkbox = ({ label, checked, onChange, id, value, color }: CheckboxProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <Box onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
      <StyledCheckboxInput
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={id}
        name={value}
      />
      <StyeldCheckboxLabel htmlFor={id}>
        <Spacing gap={8} alignItems="center">
          {checked ? (
            <CheckedCheckboxSvg
              width={20}
              height={20}
              color={color}
              hoverColor={calculateHoverColor(color, isHover)}
              isHover={isHover}
            />
          ) : (
            <UnCheckedCheckboxSvg
              width={20}
              height={20}
              color={color}
              hoverColor={calculateHoverColor(color, isHover)}
              isHover={isHover}
            />
          )}

          {label}
        </Spacing>
      </StyeldCheckboxLabel>
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCheckboxInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;

  :hover {
    cursor: pointer;
  }

  &:checked + label {
    background-color: ${color.grey01};
    border: 1px solid ${color.grey07};
  }

  width: 200px;
  height: 30px;
`;

const StyeldCheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  padding: 3px 15px;
  width: 100%;
  height: 36px;

  border: 1px solid ${color.grey04};

  border-radius: 8px;
  background-color: ${color.grey01};
  outline: none;
  box-sizing: border-box;

  color: ${color.black08};
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;

  white-space: nowrap;

  :hover {
    cursor: pointer;
    background-color: ${color.grey02};
  }

  :hover > svg {
    cursor: pointer;
  }
`;

export default Checkbox;
