import { FormDataResponseType, FormType } from '@components/types/\bform';
import { SetStateAction } from 'react';

const isIdExists = (responseData: FormDataResponseType[], idToCheck: string) => {
  return responseData.some((item) => item.id === idToCheck);
};

// id 중복 확인 후 데이터 업데이트 또는 추가 함수
const updateOrAddData = (
  responseData: FormDataResponseType[],
  setResponseData: React.Dispatch<SetStateAction<FormDataResponseType[]>>,
  newData: FormDataResponseType,
  isDuplicable?: boolean
) => {
  const id = newData.id;

  if (isIdExists(responseData, id)) {
    if (isDuplicable) {
      if (
        responseData
          .filter((item) => item.id === newData.id)
          .filter((ele) => ele.value === newData.value).length > 0
      ) {
        setResponseData((prevData) =>
          prevData.filter((item) => item.id !== newData.id || item.value !== newData.value)
        );
        return;
      } else {
        setResponseData((prevData) => [...prevData, newData]);
        return;
      }
    }
    // 이미 있는 id라면 해당 데이터를 업데이트
    setResponseData((prevData) => prevData.map((item) => (item.id === id ? newData : item)));
  } else {
    // 중복된 id가 없다면 새로운 데이터 추가

    setResponseData((prevData) => [...prevData, newData]);
  }
};

const checkRequiredData = (
  responseData: FormDataResponseType[],
  required: boolean,
  targetId: string,
  data: FormType
) => {
  if (required) {
    if (data.type === 'grid') {
      const res = data.grid.map((item) =>
        responseData.find((ele) => ele.id === item.id.toString())
      );
      if (res.includes(undefined)) {
        return false;
      } else {
        return true;
      }
    }
    if (responseData.length === 0) {
      return false;
    } else {
      if (responseData.filter((target) => target.id === targetId).length === 0) {
        return false;
      } else {
        return true;
      }
    }
  }
  return true;
};

export { isIdExists, updateOrAddData, checkRequiredData };
