import axiosWrapper from '@/api/fetch';
import { color } from '@/styles/color';
import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import Typography from '@components/atoms/Typography';
import {
  FormDataRequestType,
  FormDataResponseType,
  FormType,
  SurveyType,
} from '@components/types/\bform';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import FormCard from './FormCard';
import { Custom, ThemeColorListType } from './theme';
import { useParams } from 'react-router-dom';
import { css } from '@emotion/react';

interface PreviewFormListProps {
  colorList: ThemeColorListType;
  os?: string;
}

const PreviwFormList = ({ colorList, os = 'PC' }: PreviewFormListProps) => {
  const params = useParams();
  const [formData, setFormData] = useState<SurveyType>({
    form: [],
    id: '',
    metadata: { desc: '', form_id: '', name: '', section_count: 0, title: '' },
    orgin: '',
    theme: { theme: Custom },
  });

  const [responseData, setResponseData] = useState<FormDataResponseType[]>([]);

  const handleGetForm = async () => {
    try {
      const response: FormDataRequestType = await axiosWrapper.get(`form/${params.id}`);

      setFormData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetForm();
  }, []);

  return (
    <Box color={getRgbaText(colorList.outside)} os={os}>
      <ProfileBox>
        <TitleBox os={os}>
          <Typography color={getRgbaText(colorList.font)} size={22} weight={700} height={32}>
            {formData.metadata.title}
          </Typography>
          <Typography
            color={getRgbaText(colorList.font)}
            size={14}
            weight={500}
            whiteSpace="pre-wrap"
          >
            {formData.metadata.desc}
          </Typography>
        </TitleBox>
      </ProfileBox>
      <Spacing col width="100%" gap={12} alignItems="center">
        {formData.form.map((data: FormType | FormType[]) => {
          return (
            <FormCard
              key={Array.isArray(data) ? data.length : data.id}
              colorList={colorList}
              isPreview
              os={os}
              data={data as FormType}
              responseData={responseData}
              setResponseData={setResponseData}
            />
          );
        })}
      </Spacing>
    </Box>
  );
};

const Box = styled.div<{ color: string; os: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  padding: 20px;
  gap: 40px;
  width: 850px;

  border: 1px solid ${color.grey06};
  border-radius: 8px;

  background-color: ${(props) => props.color};
`;

const TitleBox = styled.div<{ os: string }>`
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  ${(props) =>
    props.os === 'PC'
      ? css`
          width: 100%;
        `
      : css`
          width: 380px;
        `};
`;

const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export default PreviwFormList;
