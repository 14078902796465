import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import DownArrow from '@/assets/down_arrow.svg';
import { DropDownProps, FontStyle } from '../types/atoms/dropdown';
import useClickOutside from '@/hooks/useClick';
import { color } from '@/styles/color';

const Dropdown = ({
  placeholder = 'select option',
  optionList,
  value,
  onChange,
  width = 100,
  height = 50,
  font = { color: color.black08, size: 16, height: 16, weight: 400, letterSpacing: '-0.2px' },
  style,
}: DropDownProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { ref, isClickInsideArea } = useClickOutside<HTMLUListElement>();

  useEffect(() => {
    if (open) setOpen(false);
  }, [isClickInsideArea]);

  const handleClick = (option: string) => {
    onChange(option);
    setOpen(false);
  };

  const openDropdown = () => {
    setOpen(!open);
  };

  return (
    <Container width={width} height={height}>
      <StyledDropDown
        style={style}
        onClick={openDropdown}
        role="presentation" // eslint에 따른 추가
      >
        <Content
          color={font.color}
          size={font.size}
          height={font.height}
          letterSpacing={font.letterSpacing}
          weight={font.weight}
        >
          {value || placeholder}
        </Content>
        <img src={DownArrow} width={15} height={14} />
      </StyledDropDown>
      {open && (
        <DropdownContents ref={ref}>
          {optionList.map((option: string) => (
            <DropdownElement
              color={font.color}
              size={font.size}
              height={font.height}
              letterSpacing={font.letterSpacing}
              weight={font.weight}
              onClick={() => handleClick(option)}
              onKeyDown={() => handleClick(option)} // eslint에 따른 추가
              role="option" // select - option tag 의미 전달을 위한 추가
              aria-selected={option === value}
              key={option}
            >
              <span>{option}</span>
            </DropdownElement>
          ))}
        </DropdownContents>
      )}
    </Container>
  );
};

const Container = styled.div<{ width: number; height: number }>`
  width: 50%;
  height: ${(props) => props.height}px;
  box-sizing: border-box;

  position: relative;
  background-color: #fff;
  border-radius: 6px;
`;

const StyledDropDown = styled.div`
  height: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${color.grey01};
`;

const Content = styled.div<FontStyle>`
  display: flex;
  width: 100%;
  padding: 0 15px;
  color: ${(props) => props.color};
  font-size: ${(props) => props.size}px;
  font-weight: ${(props) => props.weight};
  line-height: ${(props) => props.height}px;
  letter-spacing: ${(props) => props.letterSpacing};
`;

const DropdownContents = styled.ul`
  width: 100%;
  max-height: 180px;
  position: absolute;

  overflow-y: auto;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
  border: 1px solid #cdd3db;
  border-bottom: none;
  background-color: ${color.grey01};
  z-index: 1;
  border-radius: 6px;
`;

const DropdownElement = styled.li<FontStyle>`
  display: flex;
  padding: 15px;
  justify-content: start;

  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #cdd3db;
  cursor: pointer;
  overflow: hidden; /* 길이가 긴 텍스트를 감출 수 있도록 설정 */

  color: ${(props) => props.color};
  font-size: ${(props) => props.size}px;
  font-weight: ${(props) => props.weight};
  line-height: ${(props) => props.height}px;
  letter-spacing: ${(props) => props.letterSpacing};
  background-color: ${color.grey01};
  &:hover {
    background-color: ${color.grey02};
  }
`;

export default Dropdown;
