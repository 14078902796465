import { getRgbaText } from '@/utils';
import Spacing from '@components/atoms/Spacing';
import RequiredStar from '@/assets/required_star.svg';
import Typography from '@components/atoms/Typography';
import Checkbox from '@components/atoms/checkbox/Checkbox';
import Radio from '@components/atoms/radio/Radio';
import { ElementType, GridType } from '@components/types/\bform';
import styled from '@emotion/styled';
import { Fragment } from 'react';
import { updateOrAddData } from '../utils';

interface GridElementProps extends Omit<ElementType, 'data'> {
  data: GridType;
}

const GridElement = ({ data, colorList, responseData, setResponseData }: GridElementProps) => {
  const handleRadioCellClick = (rowId: number, columnValue: string) => {
    updateOrAddData(responseData, setResponseData, {
      id: rowId.toString(),
      value: columnValue,
    });
  };

  const handleCheckboxCellClick = (rowId: number, columnValue: string) => {
    updateOrAddData(
      responseData,
      setResponseData,
      {
        id: rowId.toString(),
        value: columnValue,
      },
      true
    );
  };

  const getRadioChecked = (index: number, value: string) => {
    if (responseData.length === 0) {
      return data.grid[index]?.checked;
    } else {
      if (responseData.filter((item) => item.id === index.toString())[0]?.value === value)
        return true;
      else return false;
    }
  };

  const getCheckboxChecked = (index: number, id: number, value: string) => {
    if (responseData.length === 0) {
      return data.grid[index]?.checked;
    } else {
      if (
        responseData.filter((item) => item.id === id.toString() && item.value === value)[0]?.id ===
        id.toString()
      )
        return true;
      else return false;
    }
  };

  return (
    <Spacing col gap={25}>
      <Spacing justifyContent="space-between" alignItems="center">
        <Spacing gap={3} alignItems="center">
          {data.required === 1 && <img src={RequiredStar} width={12} height={12} />}
          <Typography color={getRgbaText(colorList.font)} size={16} weight={700} height={26}>
            {data.question}
          </Typography>
        </Spacing>
      </Spacing>
      <SlideSpacing>
        <GridContainer count={data.grid[0].columns.length}>
          <div />
          {data.grid[0].columns.map((col) => (
            <GridRow key={col}>
              <Typography color={getRgbaText(colorList.font)} size={16} weight={700} height={26}>
                {col}
              </Typography>
            </GridRow>
          ))}
          {data.grid.map((item) => (
            <Fragment key={item.id}>
              <GridRow>
                <Typography
                  whiteSpace="nowrap"
                  color={getRgbaText(colorList.font)}
                  size={21}
                  weight={700}
                  height={26}
                >
                  {item.rows}
                </Typography>
              </GridRow>
              {item.columns.map((col, colIndex) => (
                <GridRow key={col}>
                  {data.is_check_box === 1 ? (
                    <Checkbox
                      label={col}
                      checked={getCheckboxChecked(colIndex, item.id, col) || false}
                      color={getRgbaText(colorList.element)}
                      id={item.id + 'gridCheckbox' + col}
                      value={item.id + 'gridCheckbox' + col}
                      onChange={() => handleCheckboxCellClick(item.id, col)}
                    />
                  ) : (
                    <Radio
                      label={col}
                      checked={getRadioChecked(item.id, col) || false}
                      color={getRgbaText(colorList.element)}
                      id={item.id + 'gridRadio' + col}
                      value={item.id + 'gridRadio' + col}
                      onChange={() => handleRadioCellClick(item.id, col)}
                    />
                  )}
                </GridRow>
              ))}
            </Fragment>
          ))}
        </GridContainer>
      </SlideSpacing>
    </Spacing>
  );
};

const SlideSpacing = styled.div`
  display: flex;
  gap: 6px;
  overflow-x: auto;
  padding-bottom: 5px;
`;

const GridContainer = styled.div<{ count: number }>`
  display: grid;
  grid-template-columns: auto repeat(${(props) => props.count}, 1fr);
  gap: 10px;
  width: 100%;

  place-items: center;
`;

const GridRow = styled.div`
  display: grid;
  place-items: center;
  grid-template-rows: auto repeat(1, 1fr);
`;

export default GridElement;
